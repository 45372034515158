import { Logo1Icon } from '@blocksuite/icons/rc';
import { type ReactNode } from 'react';

import * as styles from './index.css';

export const AffineOtherPageLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  /*const t = useI18n();

  const openDownloadLink = useCallback(() => {
    open(BUILD_CONFIG.downloadUrl, '_blank');
  }, []);*/

  return (
    <div className={styles.root}>
      {BUILD_CONFIG.isElectron ? (
        <div className={styles.draggableHeader} />
      ) : (
        <div className={styles.topNav}>
          <a href="/" rel="noreferrer" className={styles.affineLogo}>
            <Logo1Icon width={24} height={24} />
          </a>

          {/*
              <DesktopNavbar />
              <Button
                onClick={openDownloadLink}
                className={styles.hideInSmallScreen}
              >
                {t['com.affine.auth.open.affine.download-app']()}
              </Button>
              <MobileNavbar />
            */}
        </div>
      )}

      {children}
    </div>
  );
};
